import { number, object, oneOf, string } from 'prop-types'

import { useTriggerEvents } from 'hooks/useTriggerEvents'

import { CardLink } from '../CardLink'
import { Title } from '../Title'

import {
  CardDescription,
  CardLinkContent,
  Container,
  Content,
  Cta,
  Image,
  Item,
} from './styles'

const propTypes = {
  description: string,
  displayTitle: string,
  image: object,
  index: number,
  linkCta: object,
  linkLabel: string,
  shadow: oneOf(['light', 'dark']),
}

const defaultProps = {
  shadow: 'light',
}

const Card = ({
  description,
  displayTitle,
  image,
  index,
  linkCta,
  linkLabel,
  shadow,
}) => {
  if (image?.childImageSharp) {
    image = image.childImageSharp
  }

  const { triggerEvent } = useTriggerEvents()

  const handleClick = () => {
    if (!linkCta || !linkLabel) return

    const isPageInteraction = linkCta.type !== 'link'
    triggerEvent({
      label: `${index + 1} | ${linkLabel ?? linkCta?.url}`,
      pageInteraction: isPageInteraction,
    })
  }

  return (
    <Container as="li">
      <Cta onClick={handleClick} {...linkCta}>
        <Item as="div" shadow={shadow}>
          {image && <Image {...image} />}
          <Content>
            <Title variant="bodyXlLight" as="p" data-testid="title">
              {displayTitle}
            </Title>
            <CardDescription variant="bodySmRegular">
              {description}
            </CardDescription>
            <CardLinkContent>
              <CardLink color="primary.60" variant="bodyMdRegular">
                {linkLabel}
              </CardLink>
            </CardLinkContent>
          </Content>
        </Item>
      </Cta>
    </Container>
  )
}

Card.propTypes = propTypes
Card.defaultProps = defaultProps

export { Card }
