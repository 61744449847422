import { string } from 'prop-types'

import { Typography } from '@creditas-ui/typography'

const propTypes = {
  children: string,
  color: string,
  variant: string,
}

export const CardLink = ({ children, color, variant }) => {
  if (!children) return null

  return (
    <Typography variant={variant} color={color}>
      {children}
    </Typography>
  )
}

CardLink.propTypes = propTypes
