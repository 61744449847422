import { array, bool, oneOf } from 'prop-types'

import { Card } from '../Card'

import { Grid, List } from './styles'

const propTypes = {
  cards: array,
  hasDescription: bool,
  variant: oneOf(['light', 'dark']),
}

export const CardList = ({ cards, hasDescription, variant }) => {
  if (!cards?.length) return null

  const backgroundsScroll = {
    light: 'neutral.0',
    dark: 'neutral.90',
  }

  return (
    <List area="cards" hasDescription={hasDescription}>
      <Grid
        background={backgroundsScroll[variant]}
        scrollColor="neutral.60"
        as="ul"
      >
        {cards.map(({ id, ...card }, index) => (
          <Card key={id} index={index} shadow={variant} {...card} />
        ))}
      </Grid>
    </List>
  )
}

CardList.propTypes = propTypes
