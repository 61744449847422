import { string } from 'prop-types'

import { PreTitle as StyledPreTitle } from './styles'

const propTypes = {
  children: string,
  color: string,
}

export const PreTitle = ({ children, color = 'neutral.80' }) => {
  if (!children) return null

  return (
    <StyledPreTitle variant="exceptionMdBold" color={color}>
      {children}
    </StyledPreTitle>
  )
}

PreTitle.propTypes = propTypes
