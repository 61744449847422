import { node, string } from 'prop-types'

import { Wrapper } from './styles'

const propTypes = {
  background: string,
  children: node,
  scrollColor: string,
}

const ScrollBar = ({ background, children, scrollColor, ...others }) => (
  <Wrapper background={background} scrollColor={scrollColor} {...others}>
    {children}
  </Wrapper>
)

ScrollBar.propTypes = propTypes

export default ScrollBar
