import { css, styled } from '@creditas-ui/system'
import { selectTheme } from '@creditas-ui/utilities'

const Wrapper = styled.div(
  ({ background, scrollColor, theme }) => css`
    width: 100%;
    display: flex;
    overflow-x: auto;
    padding-bottom: 24px;

    &::-webkit-scrollbar {
      height: 8px;
      background: ${selectTheme('legacyColors.neutral04')({ theme })};
      box-shadow: inset 0 -2px 0 0 ${selectTheme(background ? `colors.${background}` : 'legacyColors.neutral02')({ theme })},
        inset 0 2px 0 0
          ${selectTheme(
            background ? `colors.${background}` : 'legacyColors.neutral02',
          )({ theme })};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${scrollColor
        ? selectTheme(`colors.${scrollColor}`)({ theme })
        : selectTheme('legacyColors.secondary04')({ theme })};
    }
  `,
)

export { Wrapper }
