import { node, object, oneOf, oneOfType, string } from 'prop-types'

import { Typography } from '@creditas-ui/typography'

const propTypes = {
  as: oneOf(['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  children: node,
  color: string,
  variant: oneOfType([string, object]),
}

const Title = ({ as, children, color = 'neutral.90', variant, ...others }) => {
  if (!children) return null

  const headingTag = as || 'h2'

  return (
    <Typography
      as={headingTag}
      color={color}
      variant={variant}
      {...others}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  )
}

Title.propTypes = propTypes

export { Title }
