import { css, styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

import ScrollBar from 'components/ScrollBar'

import { GridItem } from '../../base.styles'

export const List = styled(GridItem)(
  ({ hasDescription }) => css`
    margin-top: 39px;

    ${media.up('5xl')} {
      margin-top: ${hasDescription ? '39px' : '63px'};
    }
  `,
)

export const Grid = styled(ScrollBar)`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 16px;
  grid-template-columns: repeat(auto-fill, 286px);
  margin-left: -16px;
  margin-right: -16px;
  overflow-x: auto;
  padding-bottom: 40px;
  padding-left: 16px;
  width: auto;

  ${media.up('sm')} {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
  }

  &:after {
    content: '';
    width: 8px;

    ${media.up('7xl')} {
      width: 1px;
    }
  }

  ${media.up('5xl')} {
    grid-column-gap: 24px;
  }

  ${media.up('7xl')} {
    margin-right: 0;
    width: calc(100vw - ((100vw - 1264px) / 2));
  }
`
