import { node, string } from 'prop-types'

import { Description as DescriptionStyled } from './styles'

const propTypes = {
  children: node,
  color: string,
  variant: string,
}

const Description = ({
  children,
  color = 'neutral.80',
  variant,
  ...others
}) => {
  if (!children) return null

  return (
    <DescriptionStyled color={color} variant={variant} {...others}>
      {children}
    </DescriptionStyled>
  )
}

Description.propTypes = propTypes

export { Description }
