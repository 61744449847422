import { array, string } from 'prop-types'

import { Container, Grid, GridItem, Wrapper } from '../base.styles'
import { CardList } from '../components/CardList'
import { Description } from '../components/Description'
import { PreTitle } from '../components/PreTitle'
import { Title } from '../components/Title'

const propTypes = {
  cards: array,
  description: string,
  displayTitle: string,
  preTitle: string,
  titleHeadingTag: string,
}

const defaultProps = {
  cards: [],
}

const Template02 = ({
  cards,
  description,
  displayTitle,
  preTitle,
  titleHeadingTag,
  ...others
}) => {
  return (
    <Wrapper bgColor="colors.neutral.90" {...others}>
      <Container>
        <Grid>
          <GridItem area="heading">
            <PreTitle color="neutral.0">{preTitle}</PreTitle>
            <Title
              color="neutral.0"
              variant={{ _: 'headingMdLight', '5xl': 'headingLgLight' }}
              as={titleHeadingTag}
            >
              {displayTitle}
            </Title>
            <Description color="neutral.0" variant="bodyMdRegular">
              {description}
            </Description>
          </GridItem>
          <CardList
            hasDescription={!!description}
            variant="dark"
            cards={cards}
          />
        </Grid>
      </Container>
    </Wrapper>
  )
}

Template02.propTypes = propTypes
Template02.defaultProps = defaultProps

export default Template02
