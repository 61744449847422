import { css, styled } from '@creditas-ui/system'
import { selectTheme } from '@creditas-ui/utilities'

import { Cta as CtaComponent } from 'components/Cta'
import { LazyImage } from 'components/LazyImage'

import { GridItem } from '../../base.styles'
import { Description } from '../Description'

export const Container = styled(GridItem)`
  list-style: none;
  width: 286px;
`

export const Image = styled(LazyImage)`
  width: 100%;
`

export const Item = styled.div(
  ({ shadow, theme }) => css`
    display: flex;
    flex-direction: column;

    border-radius: 20px;
    box-shadow: ${shadow === 'light'
      ? selectTheme('shadow.xs')({ theme })
      : '0 0 1px rgba(0, 0, 0, 0.08), 0 2px 5px rgba(0, 0, 0, 0.08)'};
    overflow: hidden;
    transition: box-shadow 0.2s ease-in-out;

    &:hover {
      box-shadow: ${shadow === 'light'
        ? selectTheme('shadow.sm')({ theme })
        : '0 0 8px rgba(0, 0, 0, 0.35), 0 12px 12px rgba(0, 0, 0, 0.35);'};
    }
  `,
)

export const Content = styled.div`
  background-color: ${selectTheme('colors.neutral.0')};
  padding: 24px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const CardDescription = styled(Description)`
  color: ${selectTheme('color.neutral.60')};
  margin-top: 8px;
  margin-bottom: 40px;
`

export const CardLinkContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
`

export const Cta = styled(CtaComponent)`
  color: ${selectTheme('colors.primary.60')};
  display: flex;
  text-decoration: none;
  min-height: 100%;
`
