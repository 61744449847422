import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Container as ContainerStyled } from 'components/Container'
import { Grid as GridComponent } from 'components/Grid'

export const Container = styled(ContainerStyled)`
  display: flex;
  justify-content: space-between;
  align-self: center;
`

export const Wrapper = styled.section(
  ({ bgColor, theme }) => css`
    padding: 65px 0;
    background-color: ${selectTheme(bgColor ? bgColor : 'colors.neutral.0')({
      theme,
    })};
  `,
)

export const Grid = styled(GridComponent)`
  grid-column-gap: 10px;
  grid-template-rows: max-content;
  grid-template-areas:
    '${'heading '.repeat(4)}'
    '${'cards '.repeat(4)}';

  ${media.up('2xl')} {
    grid-column-gap: 10px;
    grid-template-areas:
      '${'heading '.repeat(8)}'
      '${'cards '.repeat(8)}';
  }

  ${media.up('5xl')} {
    grid-column-gap: 10px;
    grid-template-areas:
      '${'heading '.repeat(5)} . . . . . . .'
      '${'cards '.repeat(12)}';
  }
`

export const GridItem = styled.div(
  ({ area }) => css`
    grid-area: ${area};
  `,
)
