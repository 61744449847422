import { array, string } from 'prop-types'

import { Container, Grid, GridItem, Wrapper } from '../base.styles'
import { CardList } from '../components/CardList'
import { Description } from '../components/Description'
import { PreTitle } from '../components/PreTitle'
import { Title } from '../components/Title'

const propTypes = {
  cards: array,
  description: string,
  displayTitle: string,
  preTitle: string,
  titleHeadingTag: string,
}

const defaultProps = {
  cards: [],
}

const Template01 = ({
  cards,
  description,
  displayTitle,
  preTitle,
  titleHeadingTag,
  ...others
}) => {
  return (
    <Wrapper {...others}>
      <Container>
        <Grid>
          <GridItem area="heading">
            <PreTitle>{preTitle}</PreTitle>
            <Title
              variant={{ _: 'headingMdLight', '5xl': 'headingLgLight' }}
              as={titleHeadingTag}
            >
              {displayTitle}
            </Title>
            <Description variant="bodyMdRegular">{description}</Description>
          </GridItem>
          <CardList
            hasDescription={!!description}
            variant="light"
            cards={cards}
          />
        </Grid>
      </Container>
    </Wrapper>
  )
}

Template01.propTypes = propTypes
Template01.defaultProps = defaultProps

export default Template01
