import { oneOf } from 'prop-types'

import { renderTemplate } from 'utils/renderTemplate'

import Template01 from './Template01'
import Template02 from './Template02'

const propTypes = {
  variant: oneOf(['Template01', 'Template02']),
}

const defaultProps = {
  variant: 'Template01',
}

const ELEMENT_VARIANTS = {
  Template01,
  Template02,
}

const CrossSell = props => {
  return renderTemplate(props, ELEMENT_VARIANTS)
}

CrossSell.propTypes = propTypes
CrossSell.defaultProps = defaultProps

export default CrossSell
